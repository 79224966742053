<template>
    <el-scrollbar class="eCommerce-container">
        <div class="data-content shadow-box">
            <div class="top-bar">
                <span class="text">商品概况</span>
            </div>
            <div class="line-data">
                <div class="line-item">
                    <div class="item-left">
                        <img :src="require('../../../../assets/images/student/good-icon-03.png')" alt="">
                        <div class="text">浏览加购</div>
                    </div>
                    <div class="item-right">
                        <div class="item">
                            <div class="top">浏览量</div>
                            <div class="text">{{infoDetail.good_overview.page_view}}</div>
                        </div>
                        <div class="item">
                            <div class="top">访客指数</div>
                            <div class="text">{{infoDetail.good_overview.visitors_index}}</div>
                        </div>
                        <div class="item">
                            <div class="top">关注人数</div>
                            <div class="text">{{infoDetail.good_overview.focus_number}}</div>
                        </div>
                        <div class="item">
                            <div class="top">加购人数</div>
                            <div class="text">{{infoDetail.good_overview.purchase_number}}</div>
                        </div>
                        <div class="item">
                            <div class="top">加购商品件数</div>
                            <div class="text">{{infoDetail.good_overview.purchase_goods_number}}</div>
                        </div>
                    </div>
                </div>
                <div class="line-item">
                    <div class="item-left">
                        <img :src="require('../../../../assets/images/student/good-icon-01.png')" alt="">
                        <div class="text">搜索点击</div>
                    </div>
                    <div class="item-right">
                        <div class="item">
                            <div class="top">搜索点击人气</div>
                            <div class="text">{{infoDetail.good_overview.search_click_popularity}}</div>
                        </div>
                        <div class="item">
                            <div class="top">搜索点击指数</div>
                            <div class="text">{{infoDetail.good_overview.search_click_index}}</div>
                        </div>
                        <div class="item">
                            <div class="top">搜索点击率指数</div>
                            <div class="text">{{infoDetail.good_overview.search_click_rate_index}}</div>
                        </div>
                    </div>
                </div>
                <div class="line-item">
                    <div class="item-left">
                        <img :src="require('../../../../assets/images/student/good-icon-02.png')" alt="">
                        <div class="text">成交转化</div>
                    </div>
                    <div class="item-right">
                        <div class="item">
                            <div class="top">成交金额指数</div>
                            <div class="text">{{infoDetail.good_overview.trading_money_index}}</div>
                        </div>
                        <div class="item">
                            <div class="top">成交单量指数</div>
                            <div class="text">{{infoDetail.good_overview.trading_orders_index}}</div>
                        </div>
                        <div class="item">
                            <div class="top">成交件数指数</div>
                            <div class="text">{{infoDetail.good_overview.trading_number_index}}</div>
                        </div>
                        <div class="item">
                            <div class="top">成交转化率</div>
                            <div class="text">{{infoDetail.good_overview.trading_percent_conversion}}%</div>
                        </div>
                        <div class="item">
                            <div class="top">成交客单价</div>
                            <div class="text">{{infoDetail.good_overview.trading_price}}</div>
                        </div>
                    </div>
                </div>
                <div class="line-item">
                    <div class="item-left">
                        <img :src="require('../../../../assets/images/student/good-icon-04.png')" alt="">
                        <div class="text">店铺商品</div>
                    </div>
                    <div class="item-right">
                        <div class="item">
                            <div class="top">行业店铺数</div>
                            <div class="text">{{infoDetail.good_overview.industry_stores_number}}</div>
                        </div>
                        <div class="item">
                            <div class="top">曝光店铺数</div>
                            <div class="text">{{infoDetail.good_overview.exposure_stores_number}}</div>
                        </div>
                        <div class="item">
                            <div class="top">动销店铺数</div>
                            <div class="text">{{infoDetail.good_overview.sale_stores_number}}</div>
                        </div>
                        <div class="item">
                            <div class="top">曝光商品数</div>
                            <div class="text">{{infoDetail.good_overview.exposure_goods_number}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-content shadow-box">
            <div class="top-bar">
                <span class="text">大盘走势</span>
            </div>
            <div class="trend-wrapper">
                <div class="trend-category">
                    <div class="cate-left">
                        <p>浏览加购</p>
                        <p>搜索点击</p>
                        <p>成交转化</p>
                        <p>店铺商品</p>
                    </div>
                    <div class="cate-right">
                        <el-checkbox-group v-model="checkList" :max="5">
                            <el-checkbox :label="checkItem" v-for="checkItem in categoryList" :key="checkItem"></el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="choose-number">
                    已选择:
                    <span class="number">
                    <span>{{checkList.length}}</span> / 5
                </span>
                </div>
                <div class="trend-content" id="trendECharts"></div>
            </div>
        </div>
        <div class="data-content shadow-box">
            <div class="top-bar">
                <span class="text">子行业排行</span>
            </div>
            <el-table :data="infoDetail.sub_industry_data.list" class="customTable" style="width: 100%; margin-top: 20px;" @sort-change="sortChange">
                <el-table-column label="排名" align="center" width="100px">
                    <template slot-scope="scope">
                        <div class="sort-img" v-if="scope.row.rank < 4">
                            <img v-if="scope.row.rank === 1" :src="require('../../../../assets/images/student/num-01.png')" alt="">
                            <img v-else-if="scope.row.rank === 2" :src="require('../../../../assets/images/student/num-02.png')" alt="">
                            <img v-else :src="require('../../../../assets/images/student/num-03.png')" alt="">
                        </div>
                        <div class="sort-num" v-else>{{scope.row.rank | formatRank}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="子行业名称">
                    <template slot-scope="scope">
                        <div class="text-overflow-2">{{scope.row.sub_industry_data_name}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="trading_money_ratio" label="成交金额占比" align="center" sortable="custom">
                    <template slot-scope="scope">
                       {{scope.row.trading_money_ratio}}%
                    </template>
                </el-table-column>
                <el-table-column prop="trading_money_range" label="成交金额增幅" align="center" sortable="custom">
                    <template slot-scope="scope">
                        <div style="display: flex">
                            <div style="flex: 1; width: 1%; text-align: right">{{scope.row.trading_money_range}}%</div>
                            <div style=" margin-left: 10px; font-size: 14px; width: 30%; text-align: left">
                                <i class="iconfont" v-if="scope.row.trading_money_range_is_red" style="color: #DE1818;">&#xe614;</i>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="visitor_number_ratio" label="访客数占比" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.visitor_number_ratio}}%
                    </template>
                </el-table-column>
                <el-table-column prop="visitor_number_ratio" label="搜索点击量占比" align="center" sortable="custom">
                    <template slot-scope="scope">
                        <!-- {{scope.row.visitor_number_ratio}}% -->
                        {{scope.row.search_click_ratio}}%
                    </template>
                </el-table-column>

                <template slot="empty">
                    <div class="null-data" style="margin-top: 20px">
                        <div style="display: flex;flex-direction: column;align-items: center;">
                            <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </template>
            </el-table>
            <el-pagination class="pages-center" style="margin: 20px 0 30px" v-if="infoDetail.sub_industry_data.list.length > 0"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           layout="prev, pager, next, jumper"
                           :total="listPages.total"
                           @current-change="pagesCurrentChange">
            </el-pagination>
        </div>
    </el-scrollbar>
</template>

<script>
    import { dataCentorTplGetIndustryDataExam } from '@/utils/apis.js'
    export default {
        filters: {
            formatRank(val) {
                if (val < 10) {
                    return `0${val}`
                } else {
                    return val
                }
            }
        },
        data() {
            return {
                infoDetail: {
                    data_center_tpl_id: '',
                    market_data_id: '',
                    good_overview: {},
                    sub_industry_data: {
                        list: [],
                    },
                    trendency: {}
                },
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                order_name: void 0, // 排序字段
                order_type: void 0, // 排序方式, asc:升序, desc:降序

                checkList: ['浏览量', '访客指数', '成交金额指数', '成交转化率', '动销店铺数'],
                categoryList: ['浏览量', '访客指数', '关注人数', '加购人数', '加购商品件数',
                               '搜索点击人气', '搜索点击指数', '搜索点击率指数',
                               '成交金额指数', '成交单量指数', '成交件数指数', '成交转化率', '成交客单价',
                               '行业店铺数', '曝光店铺数', '动销店铺数', '曝光商品数'],
                trendECharts: null,
                allSeries: [],
            }
        },
        mounted() {
            this.getInfo()

            this.trendECharts = this.$echarts.init(document.getElementById('trendECharts'));
            this.getTrendECharts()

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.trendECharts.resize()
                }, 1000)
            }
        },
        computed: {
            series() {
                return this.allSeries.filter(item => {
                    return this.checkList.includes(item.name)
                })
            }
        },
        watch: {
            series(val) {
                this.getTrendECharts()
            }
        },
        methods: {
            getInfo() {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id,
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                }
                if (this.order_name) {
                    params.ordering = 1
                    params.order_name = this.order_name
                } else {
                    params.ordering = 0
                }
                if (this.order_type) {
                    params.ordering = 1
                    params.order_type = this.order_type
                } else {
                    params.ordering = 0
                }
                dataCentorTplGetIndustryDataExam(params).then((res) => {
                    this.infoDetail = res.data
                    this.listPages.total = res.data.sub_industry_data.total

                    this.infoDetail.trendency.page_view = this.changeData(res.data.trendency.page_view) // 浏览量
                    this.infoDetail.trendency.visitors_index = this.changeData(res.data.trendency.visitors_index) // 访客指数
                    this.infoDetail.trendency.focus_number = this.changeData(res.data.trendency.focus_number) // 关注人数
                    this.infoDetail.trendency.purchase_number = this.changeData(res.data.trendency.purchase_number) // 加购人数
                    this.infoDetail.trendency.purchase_goods_number = this.changeData(res.data.trendency.purchase_goods_number) // 加购商品件数

                    this.infoDetail.trendency.search_click_popularity = this.changeData(res.data.trendency.search_click_popularity) // 搜索点击人气
                    this.infoDetail.trendency.search_click_index = this.changeData(res.data.trendency.search_click_index) // 搜索点击指数
                    this.infoDetail.trendency.search_click_rate_index = this.changeData(res.data.trendency.search_click_rate_index) // 搜索点击率指数

                    this.infoDetail.trendency.trading_money_index = this.changeData(res.data.trendency.trading_money_index) // 成交金额指数
                    this.infoDetail.trendency.trading_orders_index = this.changeData(res.data.trendency.trading_orders_index) // 成交单量指数
                    this.infoDetail.trendency.trading_number_index = this.changeData(res.data.trendency.trading_number_index) // 成交件数指数
                    this.infoDetail.trendency.trading_percent_conversion = this.changeData(res.data.trendency.trading_percent_conversion) // 成交转化率
                    this.infoDetail.trendency.trading_price = this.changeData(res.data.trendency.trading_price) // 成交客单价

                    this.infoDetail.trendency.industry_stores_number = this.changeData(res.data.trendency.industry_stores_number) // 行业店铺数
                    this.infoDetail.trendency.exposure_stores_number = this.changeData(res.data.trendency.exposure_stores_number) // 曝光店铺数
                    this.infoDetail.trendency.sale_stores_number = this.changeData(res.data.trendency.sale_stores_number) // 动销店铺数
                    this.infoDetail.trendency.exposure_goods_number = this.changeData(res.data.trendency.exposure_goods_number) // 曝光商品数

                    this.getTrendSeries()
                }).catch((err) => {})
            },

            // 自定义表格数据排序
            sortChange(column, prop, order) {
                this.order_name = column.prop
                if (column.order === 'ascending') {
                    this.order_type = 'asc'
                } else if (column.order === 'descending') {
                    this.order_type = 'desc'
                } else {
                    this.order_type = void 0
                }
                this.listPages.currentPageNum = 1
                this.getInfo()
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getInfo()
            },

            // 将等于0的数值改为0.1，不然会出现数据无法显示 原因是：yAxis这个的type: 'log'
            changeData(data) {
                return data.map(item => {
                    return item === 0 ? 0.1 : item
                })
            },
            // 趋势图
            getTrendECharts() {
                this.trendECharts.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'log', // 这个设为log会导致数组中的值为0，会无法显示折线图，需将0改为0.1
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '成交转化率') {
                                    res += `<br/>${params[i].marker}${params[i].seriesName}:${params[i].value === 0.1 ? 0 : params[i].value}%`; // 将0.1的值转为0
                                } else {
                                    res += `<br/>${params[i].marker}${params[i].seriesName}:${params[i].value === 0.1 ? 0 : params[i].value}`; // 将0.1的值转为0
                                }
                            }
                            return res
                        }
                    },
                    legend: {
                        icon: 'circle',
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 10,
                        left: 20,
                        right: 20,
                        bottom: 80,
                    },
                    series: this.series
                }, true);
            },
            getTrendSeries() {
                this.allSeries = [
                    {
                        name: '浏览量',
                        type: 'line',
                        data: this.infoDetail.trendency.page_view,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#2DC079",
                            }
                        },
                    },
                    {
                        name: '访客指数',
                        type: 'line',
                        data: this.infoDetail.trendency.visitors_index,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#FFAC75",
                            }
                        },
                    },
                    {
                        name: '关注人数',
                        type: 'line',
                        data: this.infoDetail.trendency.focus_number,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#0083ff",
                            }
                        },
                    },
                    {
                        name: '加购人数',
                        type: 'line',
                        data: this.infoDetail.trendency.purchase_number,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#E43B3E",
                            }
                        },
                    },
                    {
                        name: '加购商品件数',
                        type: 'line',
                        data: this.infoDetail.trendency.purchase_goods_number,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#9878C8",
                            }
                        },
                    },

                    {
                        name: '搜索点击人气',
                        type: 'line',
                        data: this.infoDetail.trendency.search_click_popularity,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#57ef3d",
                            }
                        },
                    },
                    {
                        name: '搜索点击指数',
                        type: 'line',
                        data: this.infoDetail.trendency.search_click_index,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#ff8634",
                            }
                        },
                    },
                    {
                        name: '搜索点击率指数',
                        type: 'line',
                        data: this.infoDetail.trendency.search_click_rate_index,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#2faee9",
                            }
                        },
                    },

                    {
                        name: '成交金额指数',
                        type: 'line',
                        data: this.infoDetail.trendency.trading_money_index,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#cff133",
                            }
                        },
                    },
                    {
                        name: '成交单量指数',
                        type: 'line',
                        data: this.infoDetail.trendency.trading_orders_index,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#e7620a",
                            }
                        },
                    },
                    {
                        name: '成交件数指数',
                        type: 'line',
                        data: this.infoDetail.trendency.trading_number_index,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#00ffff",
                            }
                        },
                    },
                    {
                        name: '成交转化率',
                        type: 'line',
                        data: this.infoDetail.trendency.trading_percent_conversion,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#ff1ba2",
                            }
                        },
                    },
                    {
                        name: '成交客单价',
                        type: 'line',
                        data: this.infoDetail.trendency.trading_price,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#731bf5",
                            }
                        },
                    },

                    {
                        name: '行业店铺数',
                        type: 'line',
                        data: this.infoDetail.trendency.industry_stores_number,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#629437",
                            }
                        },
                    },
                    {
                        name: '曝光店铺数',
                        type: 'line',
                        data: this.infoDetail.trendency.exposure_stores_number,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#b14802",
                            }
                        },
                    },
                    {
                        name: '动销店铺数',
                        type: 'line',
                        data: this.infoDetail.trendency.sale_stores_number,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#031fcf",
                            }
                        },
                    },
                    {
                        name: '曝光商品数',
                        type: 'line',
                        data: this.infoDetail.trendency.exposure_goods_number,
                        smooth: true,
                        symbolSize: 0,
                        itemStyle: {
                            normal: {
                                color: "#c4569f",
                            }
                        },
                    },
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .shadow-box {
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, .05);
        border-radius: 10px;
        background: #fff;
    }
    .data-content {
         margin: 20px;
         padding: 20px;
         .top-bar {
             width: 218px;
             height: 50px;
             background: url("../../../../assets/images/student/customer-info.png") no-repeat;
             .text {
                 padding-left: 70px;
                 line-height: 50px;
                 color: #fff;
                 font-size: 20px;
             }
         }
         .line-data {
             margin-top: 30px;
             .line-item {
                 border: 1px solid #E5E5E5;
                 margin-top: 10px;
                 display: flex;
                 align-items: center;
                 text-align: center;
                 font-size: 16px;
                 .item-left {
                     width: 112px;
                     height: 118px;
                     background: #F8F8F8;
                     img {
                         margin-top: 24px;
                     }
                     .text {
                         line-height: 1;
                         margin-top: 5px;
                     }
                 }
                 .item-right {
                     width: 1%;
                     flex: 1;
                     display: flex;
                     .item {
                         width: 20%;
                         .text {
                             margin-top: 20px;
                             font-size: 18px;
                             line-height: 1;
                         }
                     }
                 }
             }
         }
         .trend-wrapper {
             background: #fff;
             padding: 20px;
             .trend-category {
                 display: flex;
                 border: 1px solid #E5E5E5;
                 .cate-left {
                     width: 120px;
                     background: #F8F8F8;
                     p {
                         line-height: 50px;
                         text-align: center;
                         font-size: 16px;
                         border-bottom: 1px solid #E5E5E5;
                         margin: 0;
                         height: 50px;
                         box-sizing: border-box;
                         &:last-child {
                             border-bottom: 0;
                         }
                     }
                 }
                 .cate-right {
                     width: 1%;
                     flex: 1;
                     .el-checkbox-group {
                         display: flex;
                         flex-wrap: wrap;
                         .el-checkbox {
                             margin-right: 0;
                             line-height: 50px;
                             margin-left: 48px;
                             width: calc(15% - 48px);
                             box-sizing: border-box;
                             &:nth-of-type(5), &:nth-of-type(13) {
                                 width: calc(40% - 48px);
                             }
                             &:nth-of-type(8) {
                                 width: calc(70% - 48px);
                             }
                             &:last-child {
                                 width: calc(55% - 48px);
                             }
                             &:after {
                                 content: '';
                                 position: absolute;
                                 left: -48px;
                                 right: 0;
                                 bottom: 0px;
                                 height: 1px;
                                 background: #E5E5E5;
                             }
                             &:nth-of-type(14), &:nth-of-type(15), &:nth-of-type(16), &:last-child {
                                 &:after {
                                     content: none;
                                 }
                             }
                         }
                     }
                 }
             }
             .choose-number {
                 margin-top: 30px;
                 font-size: 18px;
                 .number {
                     margin-left: 20px;
                     span {
                         color: #1AB163;
                     }
                 }
             }
             .trend-content {
                 margin-top: 30px;
                 height: 300px;
                 width: 100%;
             }
         }
     }
    .sort-num {
        display: inline-block;
        background: #e7f6ef;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        line-height: 24px;
        text-align: center;
    }
    .sort-img {
        display: inline-block;
        img {
            display: block;
        }
    }
</style>